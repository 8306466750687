import { Modal } from "react-bootstrap";
import ReactDatetimeClass from "react-datetime";
import styled, { css } from "styled-components";

const RegularFont = 'Rubik-Regular';
// const BoldFont = 'Rubik-Medium';
// const ExtraBold = 'Rubik-Bold';

const MediumFont = 'Rubik-Medium';

const ChallengeHeading = styled.div`
  display: flex;
  width: 100%;
  background: white;
  border-radius: 6px;
  font-family: Rubik-Medium;
  font-size: 18px;
  line-height: 24px;
  color: #002f47;
  height: 60px;
  padding-left: 0px;
  align-items: center;
  margin-bottom: 25px;
`;

const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  .stepsNameHighlight {
    width: 32.1%;
    display: flex;
    max-width: 400px;
    height: 100%;
    border-radius: 6px;
  }

  .sub-title-style{
    color:  #005C87;
font-family: Rubik;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 20px; /* 125% */

  }
  .form {
    width: calc(67.9% - 25px);
    margin-left: 25px;
    display: block;
    background: ${({ background }) => (background ? "none" : "white")};
    border-radius: 6px;
    margin-bottom: 60px;
    .heading {
      width: 100%;
      border-bottom: ${({ disable }) => disable ? "none" : "1px solid rgba(0, 92, 135, 0.30)"};
      display: flex;
      height: 70px;
      background: white;
      border-radius: 6px 6px 0 0;
      .step {
        width: 40px;
        height: 40px;
        background: #69c2ff;
        display: flex;
        align-items: center;
        justify-content: center;

        font-family: Rubik-Medium;
        font-size: 24px;
        font-weight: 500;
        line-height: 24px;
        color: white;
        margin: 15px;
      }
      .headingName {
        font-family: Rubik-Medium;
        font-size: 18px;
        line-height: 24px;
        color: #002f47;
        display: flex;
        align-items: center;
      }
    }
    .formBody {
      width: 100%;
      display: block;
      padding: 10px 50px 50px 50px;
      background: white;
      .dateCard {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-top: 10px;
        .separate {
          font-family: "Rubik-Medium";
          font-size: 16px;
          font-weight: 500;
          line-height: 20px;
          display: flex;
          align-items: center;
          // padding-top: 40px;
        }
      }
      .checkbox {
        width: 100%;
        display: flex;
        .text {
          display: flex;
          margin: auto;
          margin-right: 0px;
          align-items: center;
          font-family: Rubik;
          font-size: 16px;
          font-weight: 400;
          line-height: 20px;
          color: #9c9c9c;
        }
      }
      .radioButtonDiv {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: row;
        span {
          width: auto;
          font-family: "Rubik";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 20px;
          display: flex;
          align-items: center;
          color: #002f47;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          flex-direction: row;
          margin-right: 50px;
        }
      }

      .open-survey{
        color:rgba(0, 92, 135, 0.60);
font-family: ${RegularFont};
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 20px;
margin-left:25px;

      }
    }
    .taskHeading {
      width: 100%;
      display: flex;
      border-bottom: 1px solid rgba(156, 156, 156, 0.4);
      background: white;
      .task {
        font-family: Rubik-Medium;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        color: #002f47;
        diplay: flex;
        margin: auto;
        margin-left: 25px;
        padding: 15px 0px;
      }
      .imageWrapper {
        display: flex;
        margin: auto;
        margin-right: 15px;
        > img {
          width: 30px;
          height: 30px;
          margin-left: 10px;
        }
      }
    }
  }

  .addex {
    border: 1px dashed rgba(0, 92, 135, 0.3);
    border-radius: 6px;
    height: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
    ${"" /* margin-top:25px; */}
    ${'' /* background:#F7F5EB; */}

    button {
      height: 50px;
      width: 250px;
      background: #0d4270;
      color: white;
      border: 1px solid #0d4270;
      border-radius: 6px;
      color: #fff;
      text-align: center;
      font-family: "Rubik-Medium";
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
    }
  }
`;

const StepsWrapper = styled.div`
  display: block;
  width: 100%;
  padding: 50px 40px;
  background: white;
  border-radius: 6px;
`;

const Steps = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  .wrapper {
    width: 100%;
    display: flex;
    .number {
      width: 40px;
      height: 40px;
      background: ${({ active }) =>active ? "#69C2FF" : "rgba(156, 156, 156, 0.1)"};
      margin-right: 25px;

      font-family: ${({ active }) => (active ? "Rubik-Medium" : "Rubik")};
      font-size: 24px;
      line-height: 24px;
      color: ${({ active }) => (active ? "white" : "#9c9c9c")};
      align-items: center;
      display: flex;
      justify-content: center;
    }
    .heading {
      font-family: ${({ active }) => (active ? "Rubik-Medium" : "Rubik")};
      font-size: 18px;
      line-height: 24px;
      color: ${({ active }) => (active ? "#002f47" : "#002f47")};
      align-items: center;
      display: flex;
    }
  }
  .arrow {
    width: 100%;
    display: flex;
    .active {
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      margin: 15px 0px;
      > img {
        width: 30px;
        height: auto;
        transform: rotate(90deg);
      }
    }
  }
`;

const InputContainer = styled.div`
  width: ${({ width }) => (width ? width : "100%")};
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;
  margin: ${({ margin }) => (margin ? "none" : "auto")};
  padding-bottom: 25px;
  padding: ${({ padding }) => (padding ? padding : "0")};
  cursor: ${({ disabled }) => disabled && "not-allowed !important"};
  .radio {
    width: 50%;
    display: flex;
    > span {
      font-family: Rubik;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: #002f47;
      width: 100%;
      display: flex;
    }
  }
  .PhoneInput {
    margin-left: -35px;
    .PhoneInputInput {
      padding: 12px;
    }
  }
  .dropdown {
    > option {
      .open {
        &::placeholder {
          font-size: 18px;
          color: #9c9c9c;
          font-family: rubik;
          font-weight: 400;
          vertical-align: middle;
        }
      }
      @media (max-width: 1200px) {
        width: 100%;
      }
    }
  }

  @media (max-width: 600px) {
    width: 100%;
  }

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const StyledLabel = styled.label`
  float: left;
  display: block;
  font-size: 16px;
  color: rgba(0, 47, 71, 1);
  line-height: 21px;
  font-family: Rubik;
  font-weight: 400;
  position: relative;
  pointer-events: none;
  margin-top: 25px;
  margin-bottom: 15px;
  display: flex;
  width: ${({ width }) => (width ? width : "100%")};
  > span {
    font-size: 18px;
    color: #f6797d;
  }
  .charCount {
    display: flex;
    margin: auto;
    font-family: Rubik;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    color: #9c9c9c;
    margin-right: 0px;
  }
  .radio {
    width: auto;
    font-family: "Rubik";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #002f47;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    margin: auto;
    margin-right: 0px;
  }
`;

const StyledInputV2 = styled.input`
  border: none;
  padding: 0px 20px 0px 20px
  box-shadow: none;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 0;
  height: 60px;
  font-family: rubik-medium;
  font-weight:500;
  width: 100%;
  border-radius:6px;
  color: rgba(0, 47, 71, 1);
  border: 1px solid #cdcbcb;
  background-color: rgba(156, 156, 156, .04);
  cursor: ${({ disabled }) => disabled && "not-allowed !important"};

  &:active{
    border: 1px solid #9c9c9c;
  }
  &::placeholder {
    font-size: 16px;
    color: #9c9c9c;
    font-family: rubik;
    font-weight: 400;
    vertical-align: middle;
    line-height: 20px;
  }

  &:focus, &:active {
    outline: none;
  }
  
`;

const UploadPhotoContainer = styled.div`
width:100%;
height:auto;
display: flex;
  justify-content: flex-start;
  align-items:center;
  margin-top:15px;
  .uploadImage{
    height: 188px;
    width: 300px;
    background: rgba(156, 156, 156, 0.1);
    border: 1px solid rgba(156, 156, 156, 0.4);
    border-radius: 6px;
    display: flex;
    .uploadButton{
      background: #69C2FF;
      border-radius: 3px;
      font-family: 'Rubik';
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 24px;
display: flex;
align-items: center;
text-align: center;
color: #FFFFFF;
    }
  }
   .innerDivImageContainer{
    width:100%;
    height:100%;
   position:relative;
   display: flex;
  justify-content: flex-start;
  align-items:center;
   }

   .innerDivImageContainer img{
    height: 188px;
    width: 300px;
  object-fit:cover;
border-radius:3px;
border:1px dashed #9C9C9C;
>img{
  width:100%;
  height:100%;
}

}

.innerDivImageContainer  .infoBtn{
width:24px;
heigth:24px;
font-size:15px;
color:#fff;
border-radius:50%;
background-color:#9C9C9C;
}

@media (max-width: 600px) {
  width:100%;
   flex-direction: column;
   justify-content:center;
   .innerDivImageContainer {
    width:125px;
  height:125px;
  border-radius:50%;
  overflow:hidden;
   }
   .innerDivImageContainer  .infoBtn{display:none}
   .innerDivImageContainer  img{
  width:125px;
  height:125px;
  border-radius:50%
}
}
`;

const ImageInput = styled.label`
  color: ${({ Color }) => (Color ? Color : "white")};
  padding: 9px 20px;
  background-color: ${({ bgColor }) => (bgColor ? bgColor : "#159fc9")};
  border-radius: 30px;
  position: relative;
  text-transform: uppercase;
  cursor: pointer;
  font-size: 12px;
  line-height: ${({ lineHeight }) => lineHeight && "1.428571429"};
  margin-top: 10px;
  margin-top: ${({ margin }) => (margin ? margin : "10px")};
  font-family: "Rubik-Medium";
  cursor: ${({ disabled }) => disabled && "not-allowed !important"};

  > input {
    opacity: 0;
    z-index: -1;
    position: absolute;
    bottom: ${({ imageReload }) => imageReload && "0"};
  }

  ${(props) =>
    props.showAtBottom &&
    css`
      position: absolute;
      left: calc(40% + 20px);
      bottom: 0;

      @media (max-width: 500px) {
        left: 0;
        position: static;
      }
    `}
`;

const InputImage = styled(ImageInput)`
display: flex;
justify-content: center;
align-items:center;
width:164px;
height:50px;
background-color:#69C2FF;
font-size:18px;
margin:0 25px;
border-radius:3px;
cursor:pointer;
  padding: 0;
  margin-bottom: 0;
  margin:auto;
  > i {
font-family:rubik-medium;
font-style:normal;
font-weight:400;
font-size:18px;
color:#fff; 
text-transform: capitalize;
  }
  @media (max-width: 600px) {
    position: absolute;
background-color:#c4c4c4;
margin:0;
    height: 40px;
    width: 125px;
    top: 85px;
    border-radius: 0 0 175px 175px;
    font-size: 10px;
    >i{
      font-size:10px;
    }
  }
`;

const RemoveImage = styled.div`
display: flex;
justify-content: center;
color:white;
align-items:center;
width:164px;
height:50px;
background-color:#FD7175;
font-size:18px;
margin:0 25px;
border-radius:3px;
cursor:pointer;
padding: 0;
margin-bottom: 0;
font-family:rubik-medium;
cursor: ${({ disabled }) => disabled && "not-allowed !important"};
@media (max-width: 600px) {
  position: absolute;
background-color:#c4c4c4;

margin:0;
    height: 40px;
    width: 125px;
    top: 85px;
    border-radius: 0 0 175px 175px;
  font-size: 10px;
  >i{
    font-size:10px;
  }
}
`;

const CustomTextArea = styled.textarea`
  font-size: 16px;
  line-height: 20px;
  font-family: "Rubik-Medium";
  padding: ${({ padding }) => (padding ? "0 15px" : "15px")};
  border-radius: 4px;
  border: 1px solid rgba(156, 156, 156, 1);
  width: 100%;
  resize: none;
  color: #002f47;
  cursor: ${({ disableArea }) => disableArea && "not-allowed"};
  height: ${({ height }) => (height ? height : "165px")};
  background: rgba(156, 156, 156, 0.05);
  &:focus,
  &:active {
    outline: none;
  }

  ::placeholder {
    color: #9c9c9c;
    opacity: 1;
    font-family: "Rubik";
  }

  :-ms-input-placeholder {
    color: #9c9c9c;
  }

  ::-ms-input-placeholder {
    color: #9c9c9c;
  }
`;

const ButtonContainer = styled.div`
  margin-top: 10px;
  width: calc(100% - 80px);
  background: white;
  display: flex;
  align-items: center;
  text-align: center;
  padding: 10px 0px;
  // margin-bottom:100px;
  bottom: 0;
  position: fixed;
  right: 0;
  z-index: 100;
  .wrapper {
    width: 100%;
    max-width: 1246px;
    margin: auto;
    display: flex;
  }
  @media (max-width: 600px) {
    width: calc(100% + 32px);
    margin: 0;
    margin-left: -16px;
    justify-content: center;
    position: unset;
    flex-direction: column-reverse;
    flex-wrap: wrap;
    padding: 25px 0;
    border-top: none;
    box-shadow: unset;
    background: #f3f6f9;
  }
`;

const Button = styled.button`
  margin:auto;
  width: 200px;
  width: ${({ width }) => (width ? width : "200px")};
  background: ${({ color, disabled }) =>
    disabled ? "rgba(156, 156, 156, .6)" : color ? color : "#69c2ff"};
  border: ${({ border, disabled }) =>
    disabled
      ? "#9C9C9C"
      : border
        ? `1px solid ${border}`
        : "1px solid #69c2ff"};
  float: right;
  padding: 10px;
  font-size: 18px;
  color: ${({ textColor }) => (textColor ? textColor : "white")};
  margin-left: ${({ marginLeft, marginAuto }) =>
    marginAuto ? "auto" : marginLeft ? "0px" : "0px"};
  margin-right: ${({ marginRight }) => (marginRight ? "15px" : "0px")};
  border-radius: 3px;
  font-family:"Rubik-Medium";
  &:first-child {
  font-size: 18px;
  }
  
  >img {
    margin-left: 10px;
    font-size: 12px;
    width:10px;
    height:15px;
  }
  
  ${({ color }) =>
    color &&
    css`
      > i {
        margin-left: 0;
        margin-right: 5px;
      }
    `}
  @media (max-width: 600px) {
    width: 90%;
    margin-top:5px;
   background-color:${({ color }) => color && "transparent"}
   border:${({ color }) => color && "none"}
  }

`;

const NextButton =styled(Button)`
  margin: 0px;
  width: 200px;
  height: 50px;
  font-family: "Rubik";
  margin: 8px 0;
  margin: auto;
  margin-right: 15px;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  background: #69c2ff;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #ffffff;

  > img {
    margin-left: 10px;
    font-size: 12px;
    width: 10px;
    height: 15px;
  }
  @media (max-width: 600px) {
    width: 90%;
  }
`;

const AddTaskButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  padding-bottom: 15px;
`;

const AddTaskButton = styled.div`
  height: 40px;
  min-width: 104px;
  border-radius: 3px;
  color: ${({ active, addTask }) =>
    active ? "white" : addTask ? "#69c2ff" : "#9c9c9c"};
  background: ${({ active }) => (active ? "#69c2ff" : "white")};
  border: ${({ active, addTask }) =>
    active ? "none" : addTask ? "1px solid #69c2ff" : "1px solid #9c9c9c"};
  cursor: pointer;
  font-size: ${({ active }) => (active ? "16px" : "18px")};
  font-family: ${({ active }) => (active ? "Rubik-Medium" : "Rubik-Medium")};
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 15px 15px 0px 0px;
`;

const CustomRadioButton = styled.div`
margin-right:10px;
width:24px;
height:24px;
border:1px solid #9c9c9c
border-radius:50%;
display: flex;
justify-content:center;
align-items:center;
cursor: ${({ disabled }) => disabled && "not-allowed !important"};
>div{
  width:14px;
  height:14px;
  border-radius:50%;
  background:#fd7175;
}
>img{
  width:16px;
  height:16px;
}
`;

const FieldTitleNote = styled.div`
  font-size: 16px;
  margin-bottom : 25px;
  letter-spacing: 0px;
  line-height: 16.5px
  color: #FD7175;
  font-family: 'Rubik';
  width:100%;
  background: rgba(156, 156, 156, .04);
  background: ${({ background }) =>
    background ? "rgba(156, 156, 156, .04)" : "none"};
  padding:3px 0px 3px 10px;
  display:flex;
  align-items:center;
  .day {
    font-size: 18px;
    color: #159fc9;
  }
`;

const DropdownArrow = styled.img`
float: right;
top:${({ imageArrow }) => (imageArrow ? "110px" : " 105px")}
position: absolute;
right: 15px;
display:block
z-index:5
`;

const Slider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${({ background }) =>
    background ? background : "#69c2ff"};
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;

  &:before {
    position: absolute;
    content: "";
    height: 18px;
    width: 18px;
    left: 0px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 50%;
  }
  cursor: ${({ disabled }) =>
    disabled ? "not-allowed !important" : "pointer"};
`;

const CustomLabel = styled.label`
  position: relative;
  display: inline-block;
  width: 39px;
  height: 20px;
  margin-bottom: 0;

  @media (max-width: 500px) {
    left: calc(100% - 60px);
  }

  > input {
    display: none;
  }

  > input:checked + .slider {
    background-color: #81d487;
  }

  > input:focus + .slider {
    box-shadow: 0 0 1px #81d487;
  }

  > input:checked + .slider:before {
    -webkit-transform: translateX(18px);
    -ms-transform: translateX(18px);
    transform: translateX(18px);
  }
`;

const EditorContainer = styled.div`
  width: 100%;
  margin-top: 0px;
`;

const Layout = styled.div`
  padding: 25px;
  width: 100%;
  font-family: Rubik-Regular;
  margin-bottom: 0px;
  display: flex;
  .main {
    width: 100%;
    max-width: 1246px;
    margin: auto;
  }
`;

const StyledLabelV2 = styled.div`
  display: flex;
  width: ${({ width }) => (width ? width : "100%")};
  margin-top: 25px;
  margin-bottom: 15px;
  border: 1px solid #9c9c9c;
  border-radius: 6px;
  background: rgba(156, 156, 156, 0.05);
  padding: 20px;

  .name {
    width: auto;
    display: block;
    font-size: 16px;
    color: rgba(0, 47, 71, 1);
    line-height: 21px;
    font-family: Rubik;
    font-weight: 400;
    pointer-events: none;
    > span {
      font-size: 18px;
      color: #f6797d;
    }
  }

  .radio {
    width: 50%;
    font-family: "Rubik";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #002f47;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    margin: auto;
    margin-right: 0px;
    margin-right: ${({ marginRight }) => (marginRight ? marginRight : "0px")};
    margin-left: ${({ marginLeft }) => (marginLeft ? marginLeft : "")};
    align-items: center;
    justify-content: ${({ justify }) => (justify ? "flex-start" : "flex-end")};
  }
`;

const DownArrow = styled.div`
  float: left;
  position: absolute;
  top: 30px;
  right: 5%;
  cursor: pointer;
`;

const Locationwrapper = styled.div`
  display: flex;
  width: 100%;
  margin-top: 15px;
  norder-radius: 6px;
  border: 1px solid rgba(156, 156, 156, 1);
  background: linear-gradient(
    0deg,
    rgba(156, 156, 156, 0.05),
    rgba(156, 156, 156, 0.05)
  );
  padding: 15px 15px 0px 15px;
  flex-wrap: wrap;
  .checkBoxWidth {
    width: 33.33%;
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    span {
      width: auto;
      font-family: "Rubik";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      display: flex;
      align-items: center;
      color: #002f47;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: row;
    }
  }
`;

const Heading = styled.div`
  display: flex;
  width: 100%;
  margin-top: 15px;
  .name {
    font-family: Rubik;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    color: #002f47;
    display: flex;
    margin: auto;
    margin-left: 0px;
  }
  .selectAllRadio {
    display: flex;
    align-items: center;
    margin: auto;
    margin-right: 0px;
    span {
      width: auto;
      font-family: "Rubik";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      display: flex;
      align-items: center;
      color: #002f47;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: row;
    }
  }
`;

const QueAndCard = styled.div`
  ${"" /* margin-bottom:20px;  */}
  padding:10px 25px 25px 25px;
  border: 1px solid rgba(0, 92, 135, 0.3);
  border-radius: 7px;

  .save-buton{
    display;flex;
    justify-content:flex-end;
    width:100%;
    display:flex;
    margin-top: 25px;


  }

  .save-btn{
    width: 200px;
    width: 200px;
    background: #007AB1;
    text-align: center;
    padding: 10px;
    font-size: 18px;
    color: white;
    border-radius: 3px;
    font-family: "Rubik-Medium";
    border:none
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title {
      color: #005c87;
      font-family: Rubik-Medium;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px; /* 150% */
    }
  }
  .opinion-scale {
    .header {
      margin-top: 25px;

      .title {
        color: #005c87;
        font-family: Rubik;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px; /* 150% */
        margin-top: 25px;
      }
    }

    .toggle-btn{
      margin-top:14px;
      display:flex;
      

      .toggle-label{
        color: rgba(0, 92, 135, 0.60);
font-family: Rubik;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 20px; /* 125% */
margin-left:15px;
      }
    }

    .more-option{
      margin:25px 0px;
      color: #76AB78;
font-family: Rubik;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 24px; /* 150% */
text-decoration-line: underline;
cursor:pointer
    }

    .point-scale {
    display: flex;
    ${"" /* justify-content:space-around; */}
    align-items:center;
    margin-top: 15px;

    .point-label {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width:182px;
    }
    .input-label{
      margin-right:10px;
    }

    .point-dropdown{
      margin-top:20px;
      
    }
    

    .options{
      width: 50px;
height: 50px;
flex-shrink: 0;
border-radius: 2px;
background: #005C87;
color:white;
margin-right:15px;
display:flex;
justify-content:center;
align-items:center;
text-transform:uppercase;
    }
  }
  }

  .follow-up{
    .title{
      color: #005C87;
font-family: Rubik;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 20px; /* 125% */
margin-top:25px;
    }

    .toggle-btn{
      margin-top:14px;
      display:flex;
      

      .toggle-label{
        color: rgba(0, 92, 135, 0.60);
font-family: Rubik;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 20px; /* 125% */
margin-left:15px;
      }
    }
  }


`;

const StyledBody = styled(Modal.Body)`
  padding: 0px;
`;
const StyledModal = styled(Modal)`
  .modal-dialog {
    width: 360px;
  }
  .modal-content{
    border-radius: 6px;
  }
  .modal-header{
    background:#005C87;
    border-radius:6px 6px 0px 0px;
    height:50px;
    .close{
      color:white;
      opacity:inherit;
    }
    ${'' /* >div{
      text-align:center;
    } */}
    span{
      color:white;
      font-weight:500;
      font-size:18px;
      line-height:24px;
      font-family:${MediumFont}
    }
  }
  .modal-backdrop.in{
    opacity: .2;
  }
  .modal-dialog {
    transform: translate(-50%, -50%);
  }
  .info-popup { 
    top: 30%;
    width: 485px;
    margin: 0 auto;
    .modal-header {
      border-bottom: 0px;
    } 
  }
 @media (max-width: 500px){
   .modal-dialog {
    width: 80%;
    }
 }
 @media (min-width: 768px) {
  .modal-content{
    margin-left: ${({height}) => height ? "-150px" : '0px' };
    margin-top: ${({height}) => height ? "-150px" : '0px' };;
  }
}
`;

const CreateSurveyInfo = styled.div`
  width: 100%;
  padding: 0px 25px 25px;

  .header{
    margin:25px 0px;

text-align: center;
.title{
  font-family:${{RegularFont}};
    color: #005C87;
font-family: Rubik;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: 24px;

.well-done{
  color:  #005C87;
text-align: center;
font-family: ${MediumFont};
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: 24px; /* 133.333% */

}

.survey-name{
  color:  #005C87;
text-align: center;
font-family: ${MediumFont};
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: 24px; /* 133.333% */

}

}
  }

  .image{
  display:flex;
  justify-content:center;
}

  .sub-header{
    color: #005C87;
text-align: center;
font-family: ${RegularFont};
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: 24px; /* 133.333% */
margin-bottom:16px;
  }

  .btns{
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    width:100%;
    font-family: ${MediumFont};
    .btn1{
      background:#005C87;
      color:white;
      border:none;
      width:360px;
      text-decoration:none;
      border-radius:3px;
      height:50px;
      margin:20px 0px;
    }

    .btn2{
      background:white;
      color:#005C87;
      border:2px solid #005C87;
      width:360px;
      border-radius:4px;
      height:50px;
    }

    .or{
      color: #005C87;
text-align: center;
font-family: Rubik;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: 24px; /* 133.333% */

    }
  }
`;

const StyledDateTime = styled(ReactDatetimeClass)`
width:100%;
max-width:585px;
cursor: ${({ disabled }) => disabled && 'not-allowed !important'};
  > input {
    padding: 15px;
    width:100%;
    max-width:585px;
    border-radius:6px;
    font-size: 16px;
    height: 50px;
    color: rgb(153,153,153);
    border: 1px solid #cdcbcb;
    position: relative;
    background-color:${({ disabled, backgroundColor }) => disabled ? 'rgba(0, 92, 135, 0.05) !important' : backgroundColor ? backgroundColor : 'rgba(247, 245, 235, 0.5) !important'};
    cursor: pointer;
    font-family:rubik-medium;
    ${'' /* color: rgba(0,47,71,1); */}
    cursor: ${({ disabled }) => disabled && 'not-allowed !important'};
    color: ${({ disabled, color }) => disabled ? 'rgba(0, 92, 135, 0.60) !important' : color ? color : 'rgba(0,47,71,1) !important'};
    line-height:20px;

    &:hover, &:focus, &:active {
      outline: none;
      border-color: #cdcbcb;
      box-shadow: none;
     
    }
  }
  > input::placeholder{
font-family:rubik;
  }
  
  .rdtPicker {
    width: 100%;
  }
`;


const QuestionIconContainer = styled.div`
  display: flex;
  height: 40px;
  width: 40px;
`;


const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const CloseIcon = styled.img`
  width: 18px;
  height: 18px;
  cursor: pointer;
`;


const QuestionCard = styled.div`
  word-break: break-all;
  margin-bottom: 10px;
  border-radius: 3px;
  border: 1px solid rgba(0, 92, 135, 0.3);
  background: rgba(247, 245, 235, 0.5);
  padding: 20px;
  min-height: 50px;
  display: flex;
  align-items: center;
  cursor: grab;
`;

const QuestionText = styled.span`
  word-break: break-word;
  overflow: visible;
  text-overflow: unset;
  display: block;
  white-space: normal;
`;

const EmptyIcon = styled.div`
  background: white;
  width: 40px;
  height: 40px;
  border-radius: 3px;
`;



export {
  ChallengeHeading,
  Container,
  StepsWrapper,
  Steps,
  InputContainer,
  StyledInputV2,
  StyledLabel,
  UploadPhotoContainer,
  RemoveImage,
  InputImage,
  CustomTextArea,
  ButtonContainer,
  Button,
  NextButton,
  AddTaskButtonWrapper,
  AddTaskButton,
  CustomRadioButton,
  FieldTitleNote,
  DropdownArrow,
  CustomLabel,
  Slider,
  EditorContainer,
  Layout,
  StyledLabelV2,
  DownArrow,
  Locationwrapper,
  Heading,
  QueAndCard,
  StyledModal,
  StyledBody,
  CreateSurveyInfo,
  StyledDateTime,
  QuestionIconContainer,
  EmptyIcon,
  QuestionText,
  CloseIcon,
  QuestionCard,
  HeaderContainer

};
